import React, { useContext, useEffect } from 'react';
import { Modal, Table, Button, Form } from 'react-bootstrap';
import { AppContext } from '../../Context/AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import styles from './HistoryOverlay.module.scss';

const HistoryOverlay = ({ show, handleClose }) => {
  const { history, fetchHistory, updateInstructions } = useContext(AppContext);

  useEffect(() => {
    if (show) {
      fetchHistory();
    }
  }, [show]);

  const handleSelect = (instructions) => {
    updateInstructions(instructions);
    handleClose();
  };

  return (
    <>
      {show && (
        <div className={styles.backdrop}></div>
      )}
      <Modal show={show} onHide={handleClose} centered dialogClassName={styles.modalDialog}>
        <Modal.Header className={styles.modalHeader}>
          <Modal.Title className={styles.modalTitle}>
            <span>Instruction History</span>
            <FontAwesomeIcon icon={faTimes} className={styles.closeIcon} onClick={handleClose} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modalBody}>
          <Table striped bordered hover className={styles.table}>
            <thead>
              <tr>
                <th>Date/Time</th>
                <th>Instruction</th>
                <th>Purpose</th>
                <th>Tone</th>
                <th>Additional</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {history.map((item, index) => (
                <tr key={index}>
                  <td>{new Date(item.timestamp).toLocaleString()}</td>
                  <td><Form.Control as="textarea" plaintext readOnly defaultValue={item.instructions.Instruction} /></td>
                  <td><Form.Control as="textarea" plaintext readOnly defaultValue={item.instructions.Purpose} /></td>
                  <td><Form.Control as="textarea" plaintext readOnly defaultValue={item.instructions.Tone} /></td>
                  <td><Form.Control as="textarea" plaintext readOnly defaultValue={item.instructions.Additional} /></td>
                  <td>
                    <Button variant="primary" className={styles.select} onClick={() => handleSelect(item.instructions)}>Select</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HistoryOverlay;
