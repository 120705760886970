import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { getFormattedDateTimeIST, getDayOfWeek } from './utils/datetimeUtils';
import { appUrl } from './utils/appUrl';


export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [location, setLocation] = useState('Hyderabad');
  const [formattedDateTime, setFormattedDateTime] = useState('');
  const [dayOfWeek, setDayOfWeek] = useState('');
  const [products, setProducts] = useState({});
  const [weather, setWeather] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState({});
  const [poem, setPoem] = useState('');
  const [poemLoading, setPoemLoading] = useState(false);
  const [instructions, setInstructions] = useState({});
  const [history, setHistory] = useState([]);
  const [loadingInstructions, setLoadingInstructions] = useState(false);
  const [savingInstructions, setSavingInstructions] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${appUrl}/getproducts`);
        setProducts(response.data.response);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchProducts();
  }, []);

  useEffect(() => {
    const fetchWeather = async () => {
      try {
        const response = await axios.get(`${appUrl}/getweather`);
        setWeather(response.data.response);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchWeather();
  }, []);

  useEffect(() => {
    const updateDateTime = () => {
      setFormattedDateTime(getFormattedDateTimeIST());
      setDayOfWeek(getDayOfWeek(new Date()));
    };
    updateDateTime();
    const interval = setInterval(updateDateTime, 60000);
    return () => clearInterval(interval);
  }, []);

  const fetchPoem = async () => {
    setPoemLoading(true);
    const formattedProducts = Object.entries(selectedProducts).reduce((acc, [key, value]) => {
      const selectedItems = Object.keys(value).filter((item) => value[item]);
      if (selectedItems.length > 0) {
        acc[key] = selectedItems;
      }
      return acc;
    }, {});

    const data = {
      "selected_products": formattedProducts,
      "location": location,
      "DateTime": formattedDateTime,
      "dayofweek": dayOfWeek,
      "weather": weather
    };

    try {
      const response = await axios.post(`${appUrl}/getpoem`, data);
      if (response.status === 200) {
        setPoem(response.data.response);
      } else {
        console.error(`Received status code ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching poem:', error);
    } finally {
      setPoemLoading(false);
    }
  };

  useEffect(() => {
    fetchInstructions();
  }, []);

  const fetchInstructions = async () => {
    setLoadingInstructions(true);
    try {
      const response = await axios.get(`${appUrl}/getinstruction`);
      setInstructions(response.data);
    } catch (error) {
      console.error("Error fetching instructions:", error);
    } finally {
      setLoadingInstructions(false);
    }
  };

  const fetchHistory = async () => {
    try {
      const response = await axios.get(`${appUrl}/history`);
      setHistory(response.data);
    } catch (error) {
      console.error("Error fetching history:", error);
    }
  };

  const updateInstructions = async (newInstructions) => {
    if (JSON.stringify(newInstructions) === JSON.stringify(instructions)) {
      return;
    }
  
    setSavingInstructions(true);
    try {
      await axios.post(`${appUrl}/updateinstruction`, newInstructions);
      setInstructions(newInstructions);
    } catch (error) {
      console.error("Error updating instructions:", error);
    } finally {
      setSavingInstructions(false);
    }
  };
  

  return (
    <AppContext.Provider value={{
        location,
        formattedDateTime,
        dayOfWeek,
        products,
        weather,
        loading,
        error,
        selectedProducts,
        setSelectedProducts,
        fetchPoem,
        poem,
        poemLoading,
        instructions,
        fetchInstructions,
        updateInstructions,
        loadingInstructions,
        savingInstructions,
        history,
        fetchHistory
    }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;