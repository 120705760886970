import React, { useContext, useState } from 'react';
import { AppContext } from '../../Context/AppContext';
import { Card, Container } from 'react-bootstrap';
import styles from './WeatherComponent.module.scss';

const WeatherCard = () => {
    const { weather, loading, error } = useContext(AppContext);
    const [showDetails, setShowDetails] = useState(false);

    const toggleDetails = () => setShowDetails(!showDetails);

    if (loading) {
        return (
            <Container className={styles.container}>
                <Card className={styles.glassCard}>
                    <Card.Body>
                        <Card.Text>Loading...</Card.Text>
                    </Card.Body>
                </Card>
            </Container>
        );
    }

    if (error) {
        return (
            <Container className={styles.container}>
                <Card className={styles.glassCard}>
                    <Card.Body>
                        <Card.Text>Error fetching weather data.</Card.Text>
                    </Card.Body>
                </Card>
            </Container>
        );
    }

    return (
        <Container className={styles.container}>
            <Card className={styles.glassCard} onClick={toggleDetails}>
                <Card.Body className={`${styles.gridContainer} ${!showDetails ? styles.detailsHidden : ''}`}>
                    <div className={styles.iconTemp}>
                        <img
                            src={`http://openweathermap.org/img/wn/${weather.icon}@4x.png`}
                            alt="weather icon"
                            className={styles.weatherIcon}
                        />
                        <p className={styles.temperature}>{weather.temp}°C</p>
                        <p><span>{weather.desc}</span></p>
                    </div>
                    {showDetails && (
                        <div className={styles.weatherDetails}>
                            <p>Feels Like: <span>{weather.feels_like}°C</span></p>
                            <p>Humidity: <span>{weather.humidity}%</span></p>
                            <p>Wind Speed: <span>{weather.wind_speed} m/s</span></p>
                            <p>Sunrise: <span>{new Date(weather.sunrise * 1000).toLocaleTimeString()}</span></p>
                            <p>Sunset: <span>{new Date(weather.sunset * 1000).toLocaleTimeString()}</span></p>
                           
                        </div>
                    )}
                </Card.Body>
            </Card>
        </Container>
    );
};

export default WeatherCard;
