import { format, getDate, getMonth, getDay } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export const getFormattedDateTimeIST = () => {
  const timeZone = 'Asia/Kolkata';
  const now = new Date();

  const time = formatInTimeZone(now, timeZone, 'HH:mm');
  const day = getDate(now);
  const month = getMonth(now) + 1; // getMonth is zero-indexed
  const formattedDayOfMonth = `${day}${getDaySuffix(day)} ${getMonthName(month)}`;

  return `${time}, ${formattedDayOfMonth}`;
};

export const getDayOfWeek = (date) => {
  const days = [
    'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
  ];
  return days[getDay(date)];
};

const getDaySuffix = (day) => {
  const j = day % 10;
  const k = day % 100;
  if (j === 1 && k !== 11) {
    return 'st';
  }
  if (j === 2 && k !== 12) {
    return 'nd';
  }
  if (j === 3 && k !== 13) {
    return 'rd';
  }
  return 'th';
};

const getMonthName = (month) => {
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  return months[month - 1];
};
