import React, { useState } from 'react';
import { Navbar, Container, Row, Col, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import SettingsOverlay from '../SettingsOverlay/SettingsOverlay';
import styles from './HeaderComponent.module.scss';

const HeaderComponent = () => {
  const [showSettings, setShowSettings] = useState(false);

  const handleShowSettings = () => setShowSettings(true);
  const handleCloseSettings = () => setShowSettings(false);

  return (
    <>
      <Navbar className={styles.header} expand="lg">
        <Container fluid className="px-4">
          <Row className="w-100 align-items-center">
            <Col xs={6} md={8}>
              <Navbar.Brand href="/" className={styles.brand}>
                SipsPoet <span className={styles.versionMsg}>v2</span>
              </Navbar.Brand>
            </Col>
            <Col xs={6} md={4} className="text-end">
              <Nav className="justify-content-end">
                <Nav.Link onClick={handleShowSettings} className={styles.iconLink}>
                  <FontAwesomeIcon icon={faCog} className={styles.icon} />
                </Nav.Link>
              </Nav>
            </Col>
          </Row>
        </Container>
      </Navbar>
      <SettingsOverlay show={showSettings} handleClose={handleCloseSettings} />
    </>
  );
};

export default HeaderComponent;
