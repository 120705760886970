import React from 'react';
import './DaySky.css';

const Clouds = ({ number }) => {
  const clouds = Array.from({ length: number }, (_, index) => {
    const size = Math.random() * 40 + 40; // Cloud size between 40px and 80px
    const speed = Math.random() * 20 + 20; // Speed between 20s and 40s
    return (
      <div
        key={index}
        className="cloud"
        style={{
          top: `${Math.random() * 50}%`,
          left: `-${size}px`,
          width: `${size}px`,
          height: `${size * 0.5}px`,
          animationDuration: `${speed}s`,
          animationTimingFunction: 'linear',
        }}
      ></div>
    );
  });

  return <>{clouds}</>;
};

const DaySky = ({ children }) => {
  // Determine the number of clouds based on screen width
  const numClouds = window.innerWidth < 600 ? 5 : 10;

  return (
    <div className="day-sky">
      <div className="sun"></div>
      <Clouds number={numClouds} />
      {children}
    </div>
  );
};

export default DaySky;
