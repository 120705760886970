import React from 'react';
import './DarkSky.css'

const Stars = ({ number }) => {
  const stars = Array.from({ length: number }, (_, index) => (
    <div
      key={index}
      className="star"
      style={{
        top: `${Math.random() * 100}%`,
        left: `${Math.random() * 100}%`,
        animationDuration: `${Math.random() * 3 + 1}s`, // Random animation duration between 1s and 4s
      }}
    ></div>
  ));

  return <>{stars}</>;
};

const DarkSky = ({children}) => {
  return (
    <div className="sky">
      <Stars number={100} /> {/* Adjust the number of stars as needed */}
      {children}
    </div>
  );
};

export default DarkSky;
