import React, { useContext, useState, useEffect, useRef } from 'react';
import { AppContext } from '../../Context/AppContext';
import { Card, Form, Collapse, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import styles from './ProductList.module.scss';
import Results from '../ResultsComponent/ResultsComponent';

const splitIntoColumns = (arr, cols) => {
  const chunkSize = Math.ceil(arr.length / cols);
  const chunks = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    chunks.push(arr.slice(i, i + chunkSize));
  }
  return chunks;
};

const ProductList = () => {
  const { products, selectedProducts, setSelectedProducts, fetchPoem } = useContext(AppContext);
  const [openCategories, setOpenCategories] = useState({});
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showNext, setShowNext] = useState(false);
  const [isAllExpanded, setIsAllExpanded] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setOpenCategories(
      Object.keys(products).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {})
    );
  }, [products]);

  useEffect(() => {
    const hasSelectedProducts = Object.keys(selectedProducts).some(
      (category) => Object.keys(selectedProducts[category]).length > 0
    );
    setShowNext(hasSelectedProducts);
  }, [selectedProducts]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        collapseAll();
        setIsAllExpanded(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleCollapse = (category) => {
    setOpenCategories((prevOpenCategories) => ({
      ...prevOpenCategories,
      [category]: !prevOpenCategories[category],
    }));
  };

  const handleCheckboxChange = (category, product) => {
    setSelectedProducts((prevSelectedProducts) => {
      // Check the current state of the product (true if selected, otherwise undefined or false)
      const isCurrentlySelected = !!prevSelectedProducts[category]?.[product];
  
      // Copy the current state of products under this category
      const updatedCategory = { ...prevSelectedProducts[category] };
  
      if (isCurrentlySelected) {
        // If the product is currently selected, delete it from the category
        delete updatedCategory[product];
      } else {
        // Otherwise, add the product to the category with a value of true
        updatedCategory[product] = true;
      }
  
      // If the updated category is empty, we might also want to delete the category itself
      if (Object.keys(updatedCategory).length === 0) {
        const {[category]: _, ...otherCategories} = prevSelectedProducts;
        return otherCategories;
      }
  
      // Return the updated state including the changes to the selected category
      return {
        ...prevSelectedProducts,
        [category]: updatedCategory
      };
    });
  };
  
  

  const collapseAll = () => {
    setOpenCategories(
      Object.keys(openCategories).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {})
    );
  };

  const expandAll = () => {
    setOpenCategories(
      Object.keys(openCategories).reduce((acc, key) => {
        acc[key] = true;
        return acc;
      }, {})
    );
  };

  const toggleAllCategories = () => {
    setIsAllExpanded(!isAllExpanded);
    if (isAllExpanded) {
      collapseAll();
    } else {
      expandAll();
    }
  };

  const handleNext = async () => {
    setShowResults(true); // Show Results component immediately
    await fetchPoem();    // Fetch the poem in the background
  };

  const selectedProductsLine = Object.keys(selectedProducts).map((category) =>
    Object.keys(selectedProducts[category]).map((product) => (
      <span key={`${category}-${product}`} className={styles.selectedProduct}>
        {product},
      </span>
    ))
  );

  return (
    <div>
      {showResults ? (
// In ProductList component
<Results setShowResults={setShowResults} collapseAll={collapseAll} />
      ) : (
        <div className={styles.container} ref={containerRef}>
          <div className={styles.collapseExpandButtons} onClick={toggleAllCategories}>
            {isAllExpanded ? (
              <div className={styles.collapseAllButton}>
                <FontAwesomeIcon icon={faMinus} />
              </div>
            ) : (
              <div className={styles.expandAllButton}>
                <FontAwesomeIcon icon={faPlus} />
              </div>
            )}
          </div>
          <Row className={styles.gridContainer}>
            {Object.keys(products).map((category) => (
              <Col key={category} xs={12}>
                <Card className={styles.card}>
                  <Button
                    variant="link"
                    className={`${styles.categoryButton} ${
                      openCategories[category] ? styles.open : ''
                    }`}
                    onClick={() => toggleCollapse(category)}
                    aria-controls={`collapse-${category}`}
                    aria-expanded={openCategories[category]}
                  >
                    <h2 className={styles.h2}>{category}</h2>
                  </Button>
                  <Collapse in={openCategories[category]} unmountOnExit>
                    <div id={`collapse-${category}`}>
                      <Card.Body>
                        {splitIntoColumns(Object.keys(products[category]), 2).map(
                          (chunk, index) => (
                            <Row key={index}>
                              {chunk.map((product) => {
                                const checkboxId = `${category}-${product}`;
                                return (
                                  <Col key={product} xs={6}>
                                    <Form.Check
                                      type="checkbox"
                                      id={checkboxId}
                                      className={styles.formCheck}
                                      checked={!!selectedProducts[category]?.[product]}
                                      onChange={() => handleCheckboxChange(category, product)}
                                      label={product}
                                    />
                                  </Col>
                                );
                              })}
                            </Row>
                          )
                        )}
                      </Card.Body>
                    </div>
                  </Collapse>
                </Card>
              </Col>
            ))}
          </Row>
          {showNext && selectedProductsLine.length > 0 && (
            <Row className={styles.nextButtonContainer}>
              <Col xs={6}>
                <div className={styles.selectedProductsLine}>
                  Selected Products: {selectedProductsLine}
                </div>
              </Col>
              <Col xs={6} className="d-flex justify-content-end">
                <Button variant="primary" className={styles.nextButton} onClick={handleNext}>
                  Next
                </Button>
              </Col>
            </Row>
          )}
        </div>
      )}
    </div>
  );
};

export default ProductList;
