import React, { useState, useEffect } from 'react';
import { useContext } from 'react';
import AppContext from '../../Context/AppContext'; // Make sure to import the AppContext correctly
import HistoryOverlay from '../HistoryOverlay/HistoryOverlay'; // Make sure to import the HistoryOverlay correctly
import { faHistory } from '@fortawesome/free-solid-svg-icons'; // Import the FontAwesome history icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import the FontAwesomeIcon component
import { Button, Modal, Spinner, Form, FormGroup, FormControl, Alert } from 'react-bootstrap'; // Import the Button component from react-bootstrap

import styles from './SettingsOverlay.module.scss'

const SettingsOverlay = ({ show, handleClose }) => {
  const { instructions, fetchInstructions, updateInstructions, loadingInstructions, savingInstructions, fetchHistory } = useContext(AppContext);

  const [editableInstructions, setEditableInstructions] = useState({
    Instruction: '',
    Purpose: '',
    Tone: '',
    Additional: ''
  });

  const [showSavedMessage, setShowSavedMessage] = useState(false);
  const [showHistory, setShowHistory] = useState(false); // State to control the visibility of HistoryOverlay

  const handleShowHistory = async () => {
    await fetchHistory();
    setShowHistory(true);
  };

  useEffect(() => {
    setEditableInstructions(instructions);
  }, [instructions]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditableInstructions((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSave = async () => {
    await updateInstructions(editableInstructions);
    setShowSavedMessage(true);
    setTimeout(() => {
      setShowSavedMessage(false);
      handleClose();
    }, 2500); 
  };

  const toggleHistory = () => {
    setShowHistory(!showHistory);
  };

  return (
    <>
      {!showHistory && show && <div className={styles.modalBackdrop}></div>}
      {!showHistory && (
        <Modal show={show} onHide={handleClose} centered dialogClassName={styles.modalDialog}>
          <Modal.Header className={styles.modalHeader}>
            <Modal.Title className={styles.modalTitle}>
              Settings
              <FontAwesomeIcon onClick={handleShowHistory} className={styles.historyIcon} icon={faHistory} /> 
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={styles.modalBody}>
            {loadingInstructions ? (
              <div className="text-center">
                <Spinner animation="border" />
              </div>
            ) : (
              <>
                <Form className={styles.formGrid}>
                  <FormGroup>
                    <Form.Label className={styles.formLabel}>Instruction</Form.Label>
                    <FormControl
                      as="textarea"
                      rows={2}
                      name="Instruction"
                      value={editableInstructions.Instruction}
                      onChange={handleChange}
                      className={styles.formControl}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Form.Label className={styles.formLabel}>Purpose</Form.Label>
                    <FormControl
                      as="textarea"
                      rows={2}
                      name="Purpose"
                      value={editableInstructions.Purpose}
                      onChange={handleChange}
                      className={styles.formControl}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Form.Label className={styles.formLabel}>Tone</Form.Label>
                    <FormControl
                      as="textarea"
                      rows={2}
                      name="Tone"
                      value={editableInstructions.Tone}
                      onChange={handleChange}
                      className={styles.formControl}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Form.Label className={styles.formLabel}>Additional</Form.Label>
                    <FormControl
                      as="textarea"
                      rows={2}
                      name="Additional"
                      value={editableInstructions.Additional}
                      onChange={handleChange}
                      className={styles.formControl}
                    />
                  </FormGroup>
                </Form>
                {showSavedMessage && (
                  <Alert variant="success" className={styles.savedMessage}>
                    Settings Saved
                  </Alert>
                )}
              </>
            )}
          </Modal.Body>
          <Modal.Footer className={styles.footer}>
            <Button variant="secondary" className={styles.buttonClose} onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" className={styles.buttonSave} onClick={handleSave} disabled={savingInstructions}>
              {savingInstructions ? (
                <>
                  Save
                  <Spinner className={styles.spinner} as="span" animation="border" size="sm" />
                </>
              ) : (
                'Save'
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {showHistory && <HistoryOverlay show={showHistory} handleClose={() => setShowHistory(false)} />}
    </>
  );
};

export default SettingsOverlay;
