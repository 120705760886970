import React from 'react';
import './App.scss';
import BodyContainer from './Components/BodyContent/BodyContainer';
import { AppProvider } from './Context/AppContext';
import LocaleComponent from './Components/localeComponent/localeComponent';
import ProductList from './Components/ProductList/ProductList';
import WeatherCard from './Components/WeatherComponent/WeatherComponent';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderComponent from './Components/HeaderComponent/HeaderComponent';
import DarkSky from './Components/DarkSky/DarkSky';
import DaySky from './Components/DaySky/DaySky';

function App() {
    
    const currentHour = new Date().getHours();
   
    const isDayTime = currentHour < 18;

  
    const SkyComponent = isDayTime ? DaySky : DarkSky;

    return (
        <AppProvider>
            <HeaderComponent />
            <SkyComponent>
                <BodyContainer>
                    <Container fluid>
                        <Row className="flex-row">
                            <Col md={6}>
                                <LocaleComponent />
                            </Col>
                            <Col md={6}>
                                <WeatherCard />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ProductList />
                            </Col>
                        </Row>
                    </Container>
                </BodyContainer>
            </SkyComponent>
        </AppProvider>
    );
}

export default App;
