import React, { useContext } from 'react';
import { AppContext } from '../../Context/AppContext';
import styles from './localeComponent.module.scss';

function LocaleComponent() {
    const { location, formattedDateTime, dayOfWeek } = useContext(AppContext);

    return (
        <div className={styles.localeContainer}>
            <h1>{location}</h1>
            <p>{formattedDateTime}</p>
            <p>{dayOfWeek}</p>
        </div>
    );
}

export default LocaleComponent;
