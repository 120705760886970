import React, { useContext } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { marked } from 'marked';
import { AppContext } from '../../Context/AppContext';
import styles from './ResultsComponent.module.scss';

const Results = ({ setShowResults, collapseAll }) => {
  const { poem, poemLoading, poemError, setSelectedProducts } = useContext(AppContext);

  const handleBack = () => {
    // Reset selections and collapse all categories
    setSelectedProducts({});
    if (collapseAll) {
      collapseAll(); // Collapse all categories when going back
    }
    setShowResults(false);
  };

  const renderPoem = () => {
    if (poemError) {
      return <div className={styles.error}></div>;
    }
    if (!poem) {
      return <div className={styles.empty}></div>;
    }
    if (typeof poem === 'string') {
      return (
        <div
          className={styles.poemContent}
          dangerouslySetInnerHTML={{ __html: marked(poem) }}
        />
      );
    }
    if (typeof poem === 'object') {
      return (
        <pre className={styles.poemContent}>
          {JSON.stringify(poem, null, 2)}
        </pre>
      );
    }
    return <div className={styles.poemContent}></div>;
  };

  return (
    <div className={styles.resultsContainer}>
      <h1 className={styles.poemTitle}>Poem</h1>
      {poemLoading ? (
        <Spinner animation="border" role="status" className={styles.loadingSpinner}>
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        renderPoem()
      )}
      <Button className={styles.backButton}  onClick={handleBack}>
        Back
      </Button>
    </div>
  );
};

export default Results;
